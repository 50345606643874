import React, { Component, Fragment } from 'react';
import { Button, Icon, Header, Table, Segment, Checkbox, Loader, Popup, List, Modal, Grid, Label, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { authHeaderSet, authHeader, anonHeader } from '../helpers/auth-header.js';
import ServiceUtils from '../utils/ServiceUtils.js';
import Configuration from '../config/Configuration.js';
import { withTranslation } from 'react-i18next';

class SupportSuggestions extends Component {

    OTHER_SERVICES = "Andere Diensten";
    LEAFLET_TYPE = "LEAFLET";
    REFERRAL_TYPE = "REFERRAL";

    constructor() {
        super();
        this.config = new Configuration();
    }

    state = {
        suggestions: [],
        expandedSuggestions: [],
        suggestionCalls: 0,
        suggestionCallsBoundary: 25,
        finalSuggestions: [],
        suggestionsLoaded: false,

        healthcareServices: [],
        codableConcepts: [],
        codableConceptMappings: [],

        selectedServices: [],
        serviceRequestBundle: [],
        showSuggestionInfo: false,
        showHealthcareServiceModal: false
    }

    componentDidMount() {
        this.loadCodableConcepts();
        this.loadCodableConceptMapping();
        this.loadSuggestions();
    }

    getMailableResource = (healthcareService) => {
        return fetch(this.config.MAILABLE_RESOURCES_URL + "?healthcareService=" + healthcareService.uuid, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(mailableResources => {
                let resources = mailableResources._embedded.mailableResources;
                return resources;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    filterExpandedSuggestion = (expandedSuggestion) => {
        let suggestions = this.state.finalSuggestions;
        suggestions.push(expandedSuggestion);
        this.setState({
            finalSuggestions: suggestions,
        });
    }

    getHealthCareServices = (suggestions) => {
        for (var i = 0; i < suggestions.length; i++) {
            let suggestion = suggestions[i];
            let suggestionId = suggestion.uuid;
            let hcPromise = this.retrieveHealthcareService(suggestion.healthcareService.uuid);
            let currentComponent = this;
            let expandedSuggestion = {
                uuid: suggestionId,
                healthcareService: null,
                conceptMapping: null,
                concepts: null
            };
            if (hcPromise) {
                hcPromise.then(function (healthcareService) {
                    if (healthcareService) {
                        expandedSuggestion.healthcareService = healthcareService;
                        let conceptsPromise = currentComponent.retrieveSuggestionCodableConceptMapping(suggestionId);
                        if (conceptsPromise) {
                            conceptsPromise.then(function (concepts) {
                                expandedSuggestion.conceptMapping = concepts;
                                let codableConcepts = [];
                                for (var j = 0; j < concepts.length; j++) {
                                    let codableConcept = currentComponent.getCodableConceptFromMapping(concepts[j].uuid, healthcareService);
                                    codableConcepts.push(codableConcept);
                                }
                                expandedSuggestion.concepts = codableConcepts;
                                if (healthcareService.socialeKaartReference) {
                                    currentComponent.getServiceDetails(healthcareService.socialeKaartReference).then(function (serviceDetails) {
                                        healthcareService.serviceDetails = serviceDetails;
                                        if (healthcareService.referralMethod !== 'email') {
                                            let resourcePromise = currentComponent.getMailableResource(healthcareService);
                                            if (resourcePromise) {
                                                resourcePromise.then(function (resources) {
                                                    expandedSuggestion.healthcareService.mailableResources = resources;
                                                    currentComponent.filterExpandedSuggestion(expandedSuggestion);
                                                })
                                            }
                                        } else {
                                            currentComponent.filterExpandedSuggestion(expandedSuggestion);
                                        }
                                    })
                                } else {
                                    if (healthcareService.referralMethod !== 'email') {
                                        let resourcePromise = currentComponent.getMailableResource(healthcareService);
                                        if (resourcePromise) {
                                            resourcePromise.then(function (resources) {
                                                expandedSuggestion.healthcareService.mailableResources = resources;
                                                currentComponent.filterExpandedSuggestion(expandedSuggestion);
                                            })
                                        }
                                    } else {
                                        currentComponent.filterExpandedSuggestion(expandedSuggestion);
                                    }
                                }
                            })
                        }
                    }
                });
            }
        }
    }

    getCodableConceptFromMapping = (uuid, healthcareService) => {
        let codableConceptId = null;
        let codableConcept = null;
        for (var i = 0; i < this.state.codableConceptMappings.length; i++) {
            let codableConceptMapping = this.state.codableConceptMappings[i];
            if (codableConceptMapping.uuid === uuid) {
                codableConceptId = this.state.codableConceptMappings[i].codableConceptUuid;
                break;
            }
        }
        for (var j = 0; j < this.state.codableConcepts.length; j++) {
            if (this.state.codableConcepts[j].uuid === codableConceptId) {
                codableConcept = this.state.codableConcepts[j];
                break;
            }
        }
        if (!codableConcept) {
            console.log("Could not find codable concept " + uuid + " for HCS " + healthcareService.name);
        }
        return codableConcept;
    }

    getCodableConceptText = (concept) => {
        const { t } = this.props;
        if (concept && concept.text) {
            return t(concept.text);
        }
    }

    getCodableConcepts = (suggestion) => {
        let concepts = suggestion.concepts;
        if (concepts) {
            return <Fragment>
                <List divided>{
                    concepts.slice(0, concepts.length).map((concept, index) => {
                        return <List.Item key={index}>{this.getCodableConceptText(concept)}</List.Item>;
                    }
                    )
                }</List></Fragment>;
        } else {
            console.log("No concepts for: " + JSON.stringify(suggestion));
        }
    }

    retrievePostalCode = (addressUuid) => {
        return fetch(this.config.ADDRESSES_URL + "/" + addressUuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(address => {
                return address.postalCode;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveHealthcareService(healthcareServiceId) {
        return fetch(this.config.HEALTHCARESERVICES_URL + "/" + healthcareServiceId, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(healthcareService => {
                return healthcareService;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    loadCodableConcepts() {
        let codableTexts = [];
        return fetch(this.config.CODABLECONCEPTS_URL + "?pagesize=200", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(concepts => {
                let conceptz = concepts._embedded.codableConcepts;
                for (var i = 0; i < conceptz.length; i++) {
                    let concept = conceptz[i];
                    let uuid = concept.uuid;
                    let text = concept.text;
                    codableTexts.push({ uuid, text });
                }
                this.setState({ codableConcepts: codableTexts });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    loadCodableConceptMapping() {
        let codableConceptMappings = [];
        return fetch(this.config.CODABLECONCEPTMAPPINGS_URL + "?pagesize=800", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(concepts => {
                let conceptz = concepts._embedded.codableConceptMappings;
                for (var i = 0; i < conceptz.length; i++) {
                    let concept = conceptz[i];
                    let uuid = concept.uuid;
                    let codableConceptUuid = concept.codableConcept.uuid;
                    codableConceptMappings.push({ uuid, codableConceptUuid });
                }
                this.setState({ codableConceptMappings: codableConceptMappings });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveSuggestionCodableConceptMapping(suggestionId) {
        let codableConceptMappings = [];
        return fetch(this.config.SUGGESTION_CONCEPTS_URL + "?pagesize=100&suggestion=" + suggestionId, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(concepts => {
                let mapping = concepts._embedded.suggestionCodableConceptMappings;
                for (var i = 0; i < mapping.length; i++) {
                    let conceptMapping = mapping[i];
                    let uuid = conceptMapping.codableConceptMapping.uuid;
                    codableConceptMappings.push({ uuid });
                }
                return codableConceptMappings;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    loadSuggestions() {
    
        // Avoid calling the API if suggestions are already loaded
        if (this.state.suggestionsLoaded) {
            return;
        }
    
        // Set suggestionsLoaded to true to prevent duplicate calls
        this.setState({ suggestionsLoaded: true }, () => {
            fetch(this.config.SUGGESTIONS_URL + "?questionnaireResponse=" + this.props.questionnaireResponseId + "&pagesize=50", {
                method: 'GET',
                headers: authHeaderSet()
            })
                .then(response => {
                    if (!response.ok) {
                        this.handleResponseError(response);
                    }
                    return response.json();
                })
                .then(suggestions => {
                    let embeddedSuggestions = suggestions._embedded.suggestions;
    
                    if (embeddedSuggestions && embeddedSuggestions.length === 0 &&
                        this.state.suggestionCalls < this.state.suggestionCallsBoundary) {
                        
                        // If no suggestions found, reset suggestionsLoaded to false
                        // to allow retrying after a delay
                        this.setState({ suggestionsLoaded: false, suggestionCalls: this.state.suggestionCalls + 1 });
                        
                        setTimeout(
                            function () { this.loadSuggestions(); }
                                .bind(this),
                            1000
                        );
    
                    } else {
                        this.setState({
                            suggestions: embeddedSuggestions,
                            finalSuggestions: [], // Clear existing suggestions to prevent duplicates
                        }, () => {
                            this.getHealthCareServices(embeddedSuggestions);
                        });
                    }
                })
                .catch(error => {
                    // Reset suggestionsLoaded to false if there is an error
                    this.setState({ suggestionsLoaded: false });
                    this.handleError(error);
                });
        });
    }    

    getServiceDetailsBxlSocial = (serviceId) => {
        return fetch(this.config.BXL_SOCIAL_URL + "/" + serviceId, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(serviceDetails => {
                serviceDetails.serviceDetailsSource = this.BXL_SOCIAL;
                return serviceDetails;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getServiceDetailsSocialeKaart = (serviceId) => {
        return fetch(this.config.SOCIALE_KAART_LEAFLET_URL + "/" + serviceId, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(leaflet => {
                leaflet.serviceDetailsSource = this.SOCIALE_KAART;
                return leaflet;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getServiceDetails = (reference) => {
        let bxlSocialId = ServiceUtils.getBxlSocialReference(reference);
        if (bxlSocialId) {
            let serviceDetails = this.getServiceDetailsBxlSocial(bxlSocialId);
            return serviceDetails;
        } else {
            let serviceDetails = this.getServiceDetailsSocialeKaart(reference);
            return serviceDetails;
        }
    }

    getBxlSocialDetails = (serviceId) => {
        return fetch(this.config.BXL_SOCIAL_URL + "/" + serviceId, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(serviceDetails => {
                serviceDetails.serviceDetailsSource = this.config.BXL_SOCIAL;
                let healthcareService = this.state.healthcareService;
                healthcareService.serviceDetails = serviceDetails;
                this.setState({
                    healthcareService: healthcareService
                })
                return serviceDetails;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getSocialeKaartLeaflet = (healthcareService) => {
        return fetch(this.config.SOCIALE_KAART_LEAFLET_URL + "/" + healthcareService.socialeKaartReference, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(leaflet => {
                leaflet.serviceDetailsSource = this.config.SOCIALE_KAART;
                healthcareService.serviceDetails = leaflet;
                this.setState({
                    healthcareService: healthcareService
                })
                return leaflet;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    handleResponseError(response) {
        return {
            error: true,
            status: response.status
        };
    }

    handleError(error) {
        console.log(error.message);
    }

    getPractitionerNameInOverview = (service) => {
        if (service.practitioner) {
            return <p><Icon name='mail' color='blue' />{service.practitioner.name}</p>;
        }
    }

    selectService = (healthcareService) => {
        if (this.state.selectedServices.includes(healthcareService)) {
            const index = this.state.selectedServices.indexOf(healthcareService);
            if (index > -1) {
                this.state.selectedServices.splice(index, 1);
                this.setState({ selectedServices: this.state.selectedServices });
            }
        } else {
            this.state.selectedServices.push(healthcareService);
            this.setState({ selectedServices: this.state.selectedServices });
        }
    }

    filterHealthcareServices = () => {
        let filteredServices = [];
        if (!this.props.postalCode) {
            return;
        } else {
            for (var i = 0; i < this.state.healthcareServices.length; i++) {
                let healthcareService = this.state.healthcareServices[i];
                if (healthcareService.address) {
                    let postalCode = this.retrievePostalCode(healthcareService.address.uuid);
                    if (!postalCode) {
                        filteredServices.push(healthcareService);
                    } if (postalCode === this.props.postalCode) {
                        filteredServices.push(healthcareService);
                    }
                } else {
                    filteredServices.push(healthcareService);
                }
            }
            this.setState({ healthcareServices: filteredServices });
        }

    }

    passSelectedServices = () => {
        let finalSelection = [];
        for (var i = 0; i < this.state.selectedServices.length; i++) {
            let selectedService = this.state.selectedServices[i];
            for (var j = 0; j < this.state.finalSuggestions.length; j++) {
                let finalSuggestion = this.state.finalSuggestions[j];
                if (finalSuggestion.healthcareService.name === selectedService.name) {
                    finalSelection.push(finalSuggestion);
                }
            }
        }
        this.props.selectedServices(finalSelection);
        this.props.nextStage();
    }

    getNextButton = () => {
        const { t } = this.props;
        if (this.state.suggestionCalls === this.state.suggestionCallsBoundary) {
            return <Button as={Link} to='/' icon labelPosition='right' color='blue' floated='right'>
                {t("To Overview")}
                <Icon name='home' />
            </Button>;
        } else {
            return <Button onClick={this.passSelectedServices} icon labelPosition='right' color='blue' floated='right'>{t("Next")}<Icon name='right arrow' /></Button>
        }
    }



    simplifySuggestionCategories = (categories) => {
        let remainingCategories = [];
        let simplifiedCategory = [this.OTHER_SERVICES, []];
        let toBeRemovedCategories = [];
        for (var i = 0; i < categories.length; i++) {
            let category = categories[i];
            if (category[1].length === 1) {
                toBeRemovedCategories.push(category[0]);
                simplifiedCategory[1].push(category[1][0]);
            }
        }
        for (var j = 0; j < categories.length; j++) {
            let category = categories[j];
            if (!toBeRemovedCategories.includes(category[0])) {
                remainingCategories.push(category);
            }
        }
        remainingCategories.push(simplifiedCategory);
        return remainingCategories;
    }

    addSuggestionToCategories = (suggestion, categories) => {
        let type = suggestion.healthcareService.type;
        let resultingCategories = categories;
        let added = false;
        if (type === null || type === "") {
            type = this.OTHER_SERVICES;
        }
        for (var i = 0; i < categories.length; i++) {
            let category = categories[i];
            if (category[0] === type) {
                let suggestions = category[1];
                suggestions.push(suggestion);
                resultingCategories[i][1] = suggestions;
                added = true;
                break;
            }
        }
        if (!added) {
            let suggestions = [suggestion];
            let newCategory = [type, suggestions];
            resultingCategories.push(newCategory);
        }
        return resultingCategories;
    }

    getSuggestionCategories = (referralMethod) => {
        let categories = [];
        for (var i = 0; i < this.state.finalSuggestions.length; i++) {
            let suggestion = this.state.finalSuggestions[i];
            if (suggestion.healthcareService.referralMethod === referralMethod) {
                categories = this.addSuggestionToCategories(this.state.finalSuggestions[i], categories);
            }
        }
        categories = this.simplifySuggestionCategories(categories);
        return categories;
    }

    getHCSDescription = (comment) => {
        let description = comment;
        try {
            let commentObj = JSON.parse(comment);
            description = commentObj.description;
            description = description.split('\n').map(str => <p>{str}</p>);
        } catch (e) {
        }
        return description;
    }

    getHealthcareServiceHeader = (healthcareService) => {
        return <Header as={Link} size='small' color='blue' onClick={this.handleOpenHealthcareServiceModal.bind(this, healthcareService)}>{healthcareService.name}</Header>;
    }

    handleOpenHealthcareServiceModal = (healthcareService) => {
        if (healthcareService.socialeKaartReference) {
            this.setState({
                showHealthcareServiceModal: true,
                healthcareService: healthcareService
            }, () => {
                let bxlSocialId = ServiceUtils.getBxlSocialReference(healthcareService.socialeKaartReference);
                if (bxlSocialId) {
                    this.getBxlSocialDetails(bxlSocialId);
                } else {
                    this.getSocialeKaartLeaflet(healthcareService);
                }
            })
        } else {
            this.setState({
                showHealthcareServiceModal: true,
                healthcareService: healthcareService
            });
        }
    }

    handleCloseHealthcareServiceModal = () => {
        this.setState({ showHealthcareServiceModal: false });
    }

    ////// SERVICE RENDERING ELEMENTS //////

    // ZIPSTER AS SOURCE //

    getEmail = (service) => {
        if (service.email) {
            return <p><Icon name='mail' color='blue' />{service.email}</p>;
        }
    }

    getPhone = (service) => {
        if (service.phone) {
            return <p><Icon name='phone' color='blue' />{service.phone}</p>;
        }
    }

    getPractitionerName = (service) => {
        if (service.practitioner) {
            return <Grid.Row>
                <Grid.Column width={2}>
                    <Icon name='user' color='blue' />
                </Grid.Column>
                <Grid.Column width={14}>
                    {service.practitioner.name}
                </Grid.Column>
            </Grid.Row>;
        }
    }

    getCodableConceptsForService = (service) => {
        const { t } = this.props;
        let codableConceptTexts = [];
        if (this.state.codableConcepts) {
            for (var i = 0; i < service.codableConcepts.length; i++) {
                let serviceCodableConcept = service.codableConcepts[i];
                for (var j = 0; j < this.state.codableConcepts.length; j++) {
                    let codableConcept = this.state.codableConcepts[j];
                    if (codableConcept.uuid === serviceCodableConcept.uuid) {
                        codableConceptTexts.push(codableConcept.text);
                    }
                }
            }
            if (this.state.compactView) {
                return <List>
                    {codableConceptTexts.slice(0, codableConceptTexts.length).map((codableConceptText, index) =>
                        <List.Item key={index}><List.Icon name='check circle outline' color='blue' key={index} />{t(codableConceptText)}</List.Item>
                    )}
                </List>;
            } else {
                return <List>
                    {codableConceptTexts.slice(0, codableConceptTexts.length).map((codableConceptText, index) =>
                        <List.Item key={index}><List.Icon name='angle double right' color='blue' key={index} />{t(codableConceptText)}</List.Item>
                    )}
                </List>;
            }
        } else {
            return <Loader active inline='centered' />;
        }

    }

    getReferralMethod = (referralMethod) => {
        let directReferral = false;
        const { t } = this.props;
        if (referralMethod === "email") {
            directReferral = true;
        }
        if (directReferral) {
            return <p><Icon color='green' name='check' />{t("Direct Referrals Through Zipster")}</p>;
        } else {
            return <p><Icon color='red' name='times' />{t("No Direct Referrals Through Zipster")}</p>;
        }
    }

    getReferralMethodIcon = (referralMethod) => {
        let directReferral = false;
        if (referralMethod === "email") {
            directReferral = true;
        }
        if (directReferral) {
            return <Icon color='green' name='check' />;
        } else {
            return <Icon color='red' name='times' />;
        }
    }

    getHCSDescription = (comment) => {
        let description = comment;
        try {
            let commentObj = JSON.parse(comment);
            description = commentObj.description;
            description = description.split('\n').map(str => <p>{str}</p>);
        } catch (e) {
        }
        return description;
    }

    getHealthcareServiceHeader = (healthcareService) => {
        return <Header as={Link} size='small' color='blue' onClick={this.handleOpenHealthcareServiceModal.bind(this, healthcareService)}>{healthcareService.name}</Header>;
    }

    getSocialeKaartLabel = (healthcareService) => {
        const { t } = this.props;
        if (healthcareService.socialeKaartReference) {
            if (this.state.showHealthcareServiceModal) {
                return <Popup content={t("Source Sociale Kaart")} size='mini' trigger={<Icon floated size='large' color='orange' name='database' />} />;
            } else {
                return <Popup content={t("Source Sociale Kaart")} size='mini' trigger={<Icon floated size='mini' color='orange' name='database' />} />;
            }
        } else {
            if (this.state.showHealthcareServiceModal) {
                return <Popup content={t("Source Zipster")} size='mini' trigger={<Icon floated size='large' color='blue' name='database' />} />;
            } else {
                return <Popup content={t("Source Zipster")} size='mini' trigger={<Icon floated size='mini' name='database' />} />;
            }
        }
    }

    getPhoneFromService = (service) => {
        if (service.phone) {
            return <Grid.Row>
                <Grid.Column width={2}>
                    <Icon name='phone' color='blue' />
                </Grid.Column>
                <Grid.Column>
                    {service.phone}
                </Grid.Column>
            </Grid.Row>;
        }
    }

    getEmailFromService = (service) => {
        if (service.healthcareServiceEmails && service.healthcareServiceEmails.length > 0 && service.healthcareServiceEmails[0]) {
            return <Grid.Row>
                <Grid.Column width={2}>
                    <Icon name='mail' color='blue' />
                </Grid.Column>
                <Grid.Column>
                    {service.healthcareServiceEmails[0]}
                </Grid.Column>
            </Grid.Row>;
        }
    }

    getContactInfoFromService = (service) => {
        const { t } = this.props;
        return <Fragment>
            <Header size='small'>{t("Contact Details")}</Header>
            <Divider />
            <Grid columns={2} relaxed>
                {this.getPhoneFromService(service)}
                {this.getEmailFromService(service)}
            </Grid>
        </Fragment>
    }

    showZipsterService = (healthcareService) => {
        const { t } = this.props;
        return <Segment basic>
            <Grid columns={2}>
                <Grid.Column>
                    <Segment basic>
                        <Header size='small'>{t("Service Description")}</Header>
                        <Divider />
                        <p>{this.getHCSDescription(healthcareService.comment)}</p>
                        <Header size='small'>{t("Characteristics")}</Header>
                        <Divider />
                        <p>{this.getReferralMethod(healthcareService.referralMethod)}</p>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment basic>
                        {this.getSupportNeeds(healthcareService)}
                        {this.getParentOrganizationFromService(healthcareService)}
                        {this.getContactInfoFromService(healthcareService)}
                    </Segment>
                </Grid.Column>
            </Grid>
        </Segment>;
    }

    showZipsterServiceForMode = (healthcareService) => {
        if (this.state.showHealthcareServiceModal) {
            return this.showZipsterService(this.state.healthcareService);
        } else {
            return this.showZipsterService(healthcareService);
        }
    }

    getParentOrganizationFromService = (healthcareService) => {
        const { t } = this.props;
        return <Fragment>
            <Header size='small'>{t("Organisation")}</Header>
            <Divider />
            {healthcareService.provider.name}
        </Fragment>;
    }

    getSupportNeeds = (healthcareService) => {
        const { t } = this.props;
        return <Segment secondary raised>
            <Header size='small'>{t("Support Needs")}</Header>
            <Divider />
            {this.getCodableConceptsForService(healthcareService)}
        </Segment>;
    }

    showExternalServiceInfoForMode = (healthcareService) => {
        if (this.state.showHealthcareServiceModal) {
            return ServiceUtils.showExternalServiceInfo(this.state.healthcareService, this.state.codableConcepts, this.state.compactView, this.props);
        } else {
            return ServiceUtils.showExternalServiceInfo(healthcareService, this.state.codableConcepts, this.state.compactView, this.props);
        }
    }

    getServiceBody = (healthcareService) => {
        if (healthcareService.socialeKaartReference) {
            return this.showExternalServiceInfoForMode(healthcareService);
        } else {
            return this.showZipsterServiceForMode(healthcareService);
        }
    }

    // MODAL OPERATIONS //

    getHealthcareServiceModal = () => {
        const { t } = this.props;
        if (this.state.healthcareService) {
            return <Modal
                open={this.state.showHealthcareServiceModal}
                onClose={this.handleCloseHealthcareServiceModal}>
                <Modal.Header>
                    {ServiceUtils.getSocialeKaartLabel(this.state.healthcareService, this.state.showHealthcareServiceModal, this.props)}
                    {this.state.healthcareService.name}
                </Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        {this.getServiceBody(this.state.healthcareService)}
                        {ServiceUtils.getSocialeKaartButton(this.state.healthcareService)}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='blue' onClick={this.handleCloseHealthcareServiceModal}>
                        <Icon name='close' />{t("Close Window")}
                    </Button>
                </Modal.Actions>
            </Modal>
        }
    }

    getSuggestions() {
        const { t } = this.props;
        if (this.state.finalSuggestions &&
            this.state.finalSuggestions.length > 0) {
            return <Fragment>
                {this.getHealthcareServiceModal()}
                {this.getReferrals()}
                {this.getPrescriptions()}
                {this.getLeaflets()}
            </Fragment >;
        } else {
            if (this.state.suggestionCalls < (this.state.suggestionCallsBoundary)) {
                return <Segment basic>
                    <Loader size='huge' active inline='centered' />
                </Segment>;
            } else {
                return <Segment basic>
                    <Header size='small'>{t("Suggestions No Needs")}</Header>
                    <p>{t("Suggestions No Needs Description")}</p>
                </Segment>
            }
        }
    }

    getHCSDescription = (comment) => {
        let description = comment;
        try {
            let commentObj = JSON.parse(comment);
            description = commentObj.description;
            description = description.split('\n').map(str => <p>{str}</p>);
        } catch (e) {
        }
        return description;
    }

    getReferralSuggestionsForCategory = (category) => {
        const { t } = this.props;
        let type = category[0];
        let suggestions = category[1];
        if (!type) {
            type = this.OTHER_SERVICES;
        } else if (type.trim() === "") {
            type = this.OTHER_SERVICES;
        }
        if (suggestions.length > 0) {
            return <Fragment>
                {this.getHealthcareServiceModal()}
                <Fragment>
                    <Table.Row key={0}>
                        <Table.Cell colSpan='5'>
                            <Label color='blue' size='large' ribbon>{t(type)}</Label>
                        </Table.Cell>
                    </Table.Row>
                    {suggestions.slice(0, suggestions.length).map((suggestion, index) => {
                        return <Table.Row key={index}>
                            <Table.Cell collapsing>
                                <Checkbox name={suggestion.healthcareService} onChange={this.selectService.bind(this, suggestion.healthcareService)} />
                            </Table.Cell>
                            <Table.Cell>
                                <Popup
                                    trigger={this.getHealthcareServiceHeader(suggestion.healthcareService)}
                                    on={['hover', 'click']}
                                >{t("More Info Organisation")}
                                </Popup>
                            </Table.Cell>
                            <Table.Cell>
                                {this.getPractitionerNameInOverview(suggestion.healthcareService)}
                            </Table.Cell>
                            <Table.Cell>
                                {suggestion.healthcareService.phone}
                            </Table.Cell>
                            <Table.Cell>
                                {this.getCodableConcepts(suggestion)}
                            </Table.Cell>
                        </Table.Row>
                    })}
                </Fragment>
            </Fragment>;
        }
    }

    getReferrals = () => {
        const { t } = this.props;
        let hasReferrals = false;
        for (var i = 0; i < this.state.finalSuggestions.length; i++) {
            if (this.state.finalSuggestions[i].healthcareService.referralMethod === 'email') {
                hasReferrals = true;
                break;
            }
        }
        if (hasReferrals) {
            let suggestionCategories = this.getSuggestionCategories("email");
            return <Fragment>
                <Header as='h2' size='medium' attached='top' style={{ backgroundColor: '#385773' }} inverted>{t("Referrals")}</Header>
                <Segment attached>
                    <Table basic='very'>
                        <Table.Header fullWidth>
                            <Table.Row>
                                <Table.HeaderCell />
                                <Table.HeaderCell singleLine>{t("Organisation")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("Contact Person")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("Telephone")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("Needs")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {suggestionCategories.slice(0, suggestionCategories.length).map((category, index) => {
                                return this.getReferralSuggestionsForCategory(category);
                            })
                            }
                        </Table.Body>
                    </Table>
                </Segment>
            </Fragment >;
        }
    }

    getDownloadReference = (healthcareService) => {
        return healthcareService.mailableResources.slice(0, healthcareService.mailableResources.length).map((mailableResource, index) => {
            return <a href={mailableResource.resoureceUrl} target="_blank" rel="noopener noreferrer"> <Button size='mini' icon circular color='blue'><Icon name="paperclip" /></Button></a>
        });
    }

    getPrescriptions = () => {
        const { t } = this.props;
        let hasPrescriptions = false;
        for (var i = 0; i < this.state.finalSuggestions.length; i++) {
            if (this.state.finalSuggestions[i].healthcareService.referralMethod === 'prescription') {
                hasPrescriptions = true;
                break;
            }
        }
        if (hasPrescriptions) {
            return <Fragment>
                <Header as='h2' size='medium' attached='top' style={{ backgroundColor: '#385773' }} inverted>{t("Prescriptions")}</Header>
                <Segment attached>
                    <Table basic='very'>
                        <Table.Header fullWidth>
                            <Table.Row>
                                <Table.HeaderCell />
                                <Table.HeaderCell singleLine>{t("Organisation")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("Prescription")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("Needs")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.finalSuggestions.slice(0, this.state.finalSuggestions.length).map((suggestion, index) => {
                                if (suggestion.healthcareService.referralMethod === 'prescription') {
                                    return <Fragment>
                                        <Table.Row key={index}>
                                            <Table.Cell collapsing>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Popup
                                                    trigger={this.getHealthcareServiceHeader(suggestion.healthcareService)}
                                                    on={['hover', 'click']}
                                                >{t("More Information Prescription")}
                                                </Popup>
                                            </Table.Cell>
                                            <Table.Cell>
                                                {this.getDownloadReference(suggestion.healthcareService)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {this.getCodableConcepts(suggestion)}
                                            </Table.Cell>
                                        </Table.Row>
                                    </Fragment>
                                } else
                                    return <Fragment></Fragment>
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            </Fragment>
        }
    }

    getLeafletSuggestionsForCategory = (category) => {
        const { t } = this.props;
        let type = category[0];
        let suggestions = category[1];
        if (!type) {
            type = this.OTHER_SERVICES;
        } else if (type.trim() === "") {
            type = this.OTHER_SERVICES;
        }
        if (suggestions.length > 0) {
            return <Fragment>
                {this.getHealthcareServiceModal()}
                <Fragment>
                    <Table.Row key={0}>
                        <Table.Cell colSpan='4'>
                            <Label color='blue' size='large' ribbon>{t(type)}</Label>
                        </Table.Cell>
                    </Table.Row>
                    {suggestions.slice(0, suggestions.length).map((suggestion, index) => {
                        return <Table.Row key={index}>
                            <Table.Cell collapsing>
                                <Checkbox name={suggestion.healthcareService} onChange={this.selectService.bind(this, suggestion.healthcareService)} />
                            </Table.Cell>
                            <Table.Cell>
                                <Popup
                                    trigger={this.getHealthcareServiceHeader(suggestion.healthcareService)}
                                    on={['hover', 'click']}
                                >{t("More Information Organisation")}
                                </Popup>
                            </Table.Cell>
                            <Table.Cell>
                                {this.getDownloadReference(suggestion.healthcareService)}
                            </Table.Cell>
                            <Table.Cell>
                                {this.getCodableConcepts(suggestion)}
                            </Table.Cell>
                        </Table.Row>
                    })}
                </Fragment>
            </Fragment>;
        }
    }

    getLeaflets = () => {
        const { t } = this.props;
        let hasLeaflets = false;
        for (var i = 0; i < this.state.finalSuggestions.length; i++) {
            if (this.state.finalSuggestions[i].healthcareService.referralMethod === "NONE") {
                hasLeaflets = true;
                break;
            }
        }
        if (hasLeaflets) {
            let suggestionCategories = this.getSuggestionCategories("NONE");
            return <Fragment>
                <Header as='h2' size='medium' attached='top' style={{ backgroundColor: '#385773' }} inverted>{t("Information Services")}</Header>
                <Segment attached>
                    <Table basic='very'>
                        <Table.Header fullWidth>
                            <Table.Row>
                                <Table.HeaderCell />
                                <Table.HeaderCell singleLine>{t("Organisation")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("Links")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("Needs")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {suggestionCategories.slice(0, suggestionCategories.length).map((category, index) => {
                                return this.getLeafletSuggestionsForCategory(category);
                            })
                            }
                        </Table.Body>
                    </Table>
                </Segment>
            </Fragment>
        }
    }

    enableSuggestionInfo = () => {
        this.setState({ enableSuggestionInfo: !this.state.enableSuggestionInfo });
    }

    render() {
        const { t } = this.props;
        return <Fragment>
            <Segment basic>
                <Grid columns={2}>
                    <Grid.Column width={8}>
                        <Header as='h1'>{t("Suggestions")}</Header>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Button color='orange' floated="right" circular size='mini' icon='question' onClick={this.enableSuggestionInfo} />
                    </Grid.Column>
                </Grid>
                <Segment hidden={!this.state.enableSuggestionInfo} raised>
                    <Header>{t("Referral VS Informing")}</Header>
                    <p>{t("Referral VS Informing Description")}</p>
                    <List relaxed>
                        <List.Item>
                            <List.Icon name='mail forward' />
                            <List.Content>{t("Referral Explanation")}</List.Content></List.Item>
                        <List.Item>
                            <List.Icon name='handshake' />
                            <List.Content>{t("Informing Explanation")}</List.Content></List.Item>
                    </List>
                </Segment>
                {this.getSuggestions()}
            </Segment>
            {this.getNextButton()}
        </Fragment>
    }
}

export default withTranslation()(SupportSuggestions);
