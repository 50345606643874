import React, { Component } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Header, Segment, Divider, Grid, Icon, Button, Loader, List, Message, Image, Container } from 'semantic-ui-react';
import { authHeader, authHeaderPatch, authHeaderSet } from '../helpers/auth-header.js';
import Configuration from '../config/Configuration.js';
import AuthenticationService from '../api/AuthenticationService.js';
import { getAge } from '../helpers/date-utils.js';
import queryString from 'query-string';
import logogreen from '../images/state-green.png';
import logoorange from '../images/state-orange.png';
import logored from '../images/state-red.png';
import logogrey from '../images/state-grey.png';
import logoyellow from '../images/state-yellow.png';
import { withTranslation } from 'react-i18next';
import { createMedia } from "@artsy/fresnel";

const AppMedia = createMedia({
    breakpoints: {
        mobile: 320,
        tablet: 768,
        computer: 992,
        largeScreen: 1200,
        widescreen: 1920
    }
});

const { Media, MediaContextProvider } = AppMedia;

class ServiceRequest extends Component {

    constructor() {
        super();
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        this.state = {
            activeUser: this.authenticationService.getActiveUser(),
            practitionerRole: null,
            practitioner: null,
            patient: null,
            patientZipCode: null,
            encounterId: null,
            enableRegistrationSegment: false,
            procedureCreated: false,
            codableConcepts: [],
            serviceRequest: null,
            pagesize: 40,
            healthcareService: null
        }
    }

    loadCodableConcepts() {
        let codableTexts = [];
        return fetch(this.config.CODABLECONCEPTS_URL + "?pagesize=200", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(concepts => {
                let conceptz = concepts._embedded.codableConcepts;
                for (var i = 0; i < conceptz.length; i++) {
                    let concept = conceptz[i];
                    let uuid = concept.uuid;
                    let text = concept.text;
                    codableTexts.push({ uuid, text });
                }
                this.setState({ codableConcepts: codableTexts });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    updateServiceRequest = (action) => {
        return fetch(this.config.SERVICEREQUESTS_URL + "/" + this.state.serviceRequest.uuid, {
            method: 'PATCH',
            headers: authHeaderPatch(),
            body: JSON.stringify({
                status: action
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    createProcedure = (encounterId, action) => {
        return fetch(this.config.PROCEDURES_URL, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                status: action,
                basedOn: this.state.serviceRequest.uuid,
                encounter: encounterId,
                healthcareService: this.state.healthcareService.uuid
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                return response.json();
            }
        }).then(procedure => {
            this.setState({ procedureCreated: true });
            this.updateServiceRequest(action);
        }).catch(error => {
            this.handleError(error);
        });
    }

    createEncounterAndProcedure = (action) => {
        return fetch(this.config.ENCOUNTERS_URL, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                subject: this.state.patient.uuid,
                participant: this.state.activeUser.uuid,
                date: new Date(),
                type: this.config.ENCOUNTER_FOLLOWUP
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                return response.json();
            }
        }).then(encounter => {
            this.setState({ encounterId: encounter.id });
            this.createProcedure(encounter.id, action);
        }).catch(error => {
            this.handleError(error);
        });
    }

    retrievePatient = () => {
        return fetch(this.config.PATIENTS_URL + "/" + this.state.serviceRequest.encounter.subject.uuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(patient => {
                this.setState({ patient: patient });
                if (patient.address) {
                    this.retrieveAddress(patient.address.uuid);
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveAddress = (addressUuid) => {
        return fetch(this.config.ADDRESSES_URL + "/" + addressUuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(address => {
                this.setState({
                    patientZipCode: address.postalCode
                });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrievePractitioner(practitionerId) {
        return fetch(this.config.PRACTITIONERS_URL + "/" + practitionerId, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(practitioner => {
                this.setState({
                    practitioner: practitioner
                });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrievePractitionerRole() {
        return fetch(this.config.PRACTITIONERROLES_URL + "/" + this.state.serviceRequest.encounter.participant.uuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(practitionerRole => {
                this.setState({
                    practitionerRole: practitionerRole
                });
                this.retrievePractitioner(practitionerRole.practitioner.uuid);
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveEncounter = (encounterId) => {
        return fetch(this.config.ENCOUNTERS_URL + "/" + encounterId, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            }).catch(error => {
                this.handleError(error);
            });
    }

    retrieveServiceRequestConcepts = (serviceRequestId) => {
        return fetch(this.config.SERVICEREQUESTS_CONCEPTS_URL + "?serviceRequest=" + serviceRequestId + "&pagesize=" + this.state.pagesize, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(serviceRequests => {
                let codableConceptIds = [];
                let embedded = serviceRequests._embedded.serviceRequestCodableConcepts;
                for (var i = 0; i < embedded.length; i++) {
                    codableConceptIds.push(embedded[i].codableConcept.uuid);
                }
                return codableConceptIds;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getCodableConcept = (uuid) => {
        for (var i = 0; i < this.state.codableConcepts.length; i++) {
            if (this.state.codableConcepts[i].uuid === uuid) {
                return this.state.codableConcepts[i];
            }
        }
    }

    retrieveAssignedHealthcareService = (uuid) => {
        return fetch(this.config.ASSIGNED_HEALTHCARE_SERVICES_URL + "/" + uuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            }).then(assignedHealthcareService => {
                this.setState({
                    healthcareService: assignedHealthcareService.healthcareService
                })
            }).catch(error => {
                this.handleError(error);
            });
    }

    retrieveServiceRequest = (serviceRequestId) => {
        return fetch(this.config.SERVICEREQUESTS_URL + "/" + serviceRequestId, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(serviceRequest => {
                let currentComponent = this;
                this.setState({
                    serviceRequest: serviceRequest,
                    requestCallDone: true
                }, () => {
                    this.retrieveServiceRequestConcepts(serviceRequestId).then(function (codableConceptIds) {
                        let fullCodableConcepts = [];
                        for (var j = 0; j < codableConceptIds.length; j++) {
                            let codableConcept = currentComponent.getCodableConcept(codableConceptIds[j]);
                            if (codableConcept) {
                                fullCodableConcepts.push(codableConcept);
                            }
                        }
                        return fullCodableConcepts;
                    }).then(function (fullCodableConcepts) {
                        serviceRequest.codableConcepts = fullCodableConcepts;
                        currentComponent.retrieveAssignedHealthcareService(serviceRequest.performer.uuid);
                        currentComponent.setState({
                            serviceRequest: serviceRequest
                        });
                    })

                })
                return serviceRequest;
            })
            .then(serviceRequest => {
                let currentComponent = this;
                let promise = this.retrieveEncounter(serviceRequest.encounter.uuid)
                if (promise) {
                    promise.then(function (encounter) {
                        if (encounter) {
                            serviceRequest.encounter = encounter;
                            currentComponent.setState({
                                serviceRequest: serviceRequest
                            }, () => {
                                currentComponent.retrievePatient();
                                currentComponent.retrievePractitionerRole()
                            });
                        }
                    });
                }
                return serviceRequest;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    handleError = (error) => {
        console.log(error.message);
    }

    getServiceRequestId = () => {
        const values = queryString.parse(this.props.location.search);
        if (values.id) {
            return values.id;
        } else {
            return null;
        };
    }

    componentDidMount = () => {
        this.loadCodableConcepts();
        if (!this.state.serviceRequest) {
            if (this.props.location.state && this.props.location.state.serviceRequest) {
                this.setState({
                    serviceRequest: this.props.location.state.serviceRequest
                }, () => {
                    this.retrievePatient();
                    this.retrievePractitionerRole();
                    this.retrieveAssignedHealthcareService(this.props.location.state.serviceRequest.performer.uuid);
                });
            } else {
                let serviceRequestId = this.getServiceRequestId();
                if (serviceRequestId) {
                    this.retrieveServiceRequest(serviceRequestId);
                }
            }
        }
    }

    getPhone = (phone) => {
        if (phone) {
            return <p><Icon name='phone' color='blue' />{phone}</p>
        } else return null;
    }

    getMail = (mail) => {
        if (mail) {
            return <p><Icon name='mail' color='blue' />{mail}</p>
        } else return null;
    }

    enableRegistrationSegment = () => {
        this.setState({
            enableRegistrationSegment: true
        });
    }

    showRegistrationButton = () => {
        const { t } = this.props;
        if (!this.state.enableRegistrationSegment) {
            return <Segment attached='bottom'>
                <Grid columns={3} textAlign='center'>
                    <Grid.Column></Grid.Column>
                    <Grid.Column>
                        <Button onClick={this.enableRegistrationSegment} color='blue'>{t("Register Follow-up")}</Button>
                    </Grid.Column>
                    <Grid.Column></Grid.Column>
                </Grid>
            </Segment>;
        } else return null;
    }

    registerProcedure = (action) => {
        return this.createEncounterAndProcedure(action);
    }

    showRegistrationSegment = () => {
        const { t } = this.props;
        if (this.state.enableRegistrationSegment && !this.state.procedureCreated) {
            if (this.state.serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_PLANNED ||
                this.state.serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_WAITING_LIST) {
                return <Segment attached='bottom' textAlign='left'>
                    <Header textAlign='center'>{t("Register Follow-up")}</Header>
                    <Divider />
                    <Grid columns={2}>
                        <Grid.Column width={4}></Grid.Column>
                        <Grid.Column width={12}>
                            <p><Button circular onClick={this.registerProcedure.bind(this, this.config.SERVICE_REQUEST_STATUS_WAITING_LIST)} icon color='blue'><Icon name='wait' /></Button>{t("SR Waiting List", { givenName: this.state.patient.givenName })}</p>
                            <p><Button circular onClick={this.registerProcedure.bind(this, this.config.SERVICE_REQUEST_STATUS_SUPPORT)} icon color='blue'><Icon name='thumbs up' /></Button>{t("SR Support", { givenName: this.state.patient.givenName })}</p>
                            <p><Button circular onClick={this.registerProcedure.bind(this, this.config.SERVICE_REQUEST_STATUS_FORWARD)} icon color='blue'><Icon name='mail forward' /></Button>{t("SR Referred", { givenName: this.state.patient.givenName })}</p>
                            <p><Button circular onClick={this.registerProcedure.bind(this, this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT)} icon color='blue'><Icon name='close' /></Button>{t("SR No Support", { givenName: this.state.patient.givenName })}</p>
                            <p><Button circular onClick={this.registerProcedure.bind(this, this.config.SERVICE_REQUEST_STATUS_ALREADY_SUPPORTED)} icon color='blue'><Icon name='thumbs up' /></Button>{t("SR Already Supported", { givenName: this.state.patient.givenName })}</p>
                            <p><Button circular onClick={this.registerProcedure.bind(this, this.config.SERVICE_REQUEST_STATUS_NO_SHOW)} icon color='blue'><Icon name='dont' /></Button>{t("SR No Show", { givenName: this.state.patient.givenName })}</p>
                            <p><Button circular onClick={this.registerProcedure.bind(this, this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT_WANTED)} icon color='blue'><Icon name='dont' /></Button>{t("SR No Support Wanted", { givenName: this.state.patient.givenName })}</p>
                        </Grid.Column>
                    </Grid>
                </Segment>;
            } else if (this.state.serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_SUPPORT) {
                return <Segment attached='bottom' textAlign='left'>
                    <Header textAlign='center'>{t("Register Follow-up")}</Header>
                    <Divider />
                    <Grid columns={2}>
                        <Grid.Column width={4}></Grid.Column>
                        <Grid.Column width={12}>
                            <p><Button circular onClick={this.registerProcedure.bind(this, this.config.SERVICE_REQUEST_STATUS_SUPPORT_ENDED)} icon color='blue'><Icon name='close' /></Button>{t("SR Support Ended", { givenName: this.state.patient.givenName })}</p>
                        </Grid.Column>
                    </Grid>
                </Segment>;
            } else if (this.state.serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_SUPPORT_ENDED) {
                return <Segment attached='bottom' textAlign='left'>
                    <Header textAlign='center'>{t("SR Support Already Ended")}</Header>
                </Segment>;
            } else {
                return <Segment attached='bottom' textAlign='left'>
                    <Header textAlign='center'>{t("Register Follow-up")}</Header>
                    <Divider />
                    <Grid columns={2}>
                        <Grid.Column width={4}></Grid.Column>
                        <Grid.Column width={12}>
                            <p><Button circular onClick={this.registerProcedure.bind(this, this.config.SERVICE_REQUEST_STATUS_PLANNED)} icon color='blue'><Icon name='thumbs up' /></Button>{t("SR Planned", { givenName: this.state.patient.givenName })}</p>
                            <p><Button circular onClick={this.registerProcedure.bind(this, this.config.SERVICE_REQUEST_STATUS_WAITING_LIST)} icon color='blue'><Icon name='wait' /></Button>{t("SR Waiting List", { givenName: this.state.patient.givenName })}</p>
                            <p><Button circular onClick={this.registerProcedure.bind(this, this.config.SERVICE_REQUEST_STATUS_NOT_REACHABLE)} icon color='blue'><Icon name='dont' /></Button>{t("SR Unreachable", { givenName: this.state.patient.givenName })}</p>
                            <p><Button circular onClick={this.registerProcedure.bind(this, this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT_POSSIBLE)} icon color='blue'><Icon name='close' /></Button>{t("SR No Support Possible", { givenName: this.state.patient.givenName })}</p>
                        </Grid.Column>
                    </Grid>
                </Segment>;
            }
        } else if (this.state.procedureCreated) {
            return <Message positive>
                {t("Follow-up Registered")}
            </Message>;
        }
    }

    getCodableConceptText = (serviceRequest) => {
        const { t } = this.props;
        let codableConceptTexts = [];
        let codableConcepts = serviceRequest.codableConcepts;
        for (var j = 0; j < codableConcepts.length; j++) {
            let codableConcept = codableConcepts[j];
            for (var i = 0; i < this.state.codableConcepts.length; i++) {
                if (this.state.codableConcepts[i].uuid === codableConcept.uuid) {
                    codableConceptTexts.push(this.state.codableConcepts[i].text);
                }
            }
        }
        if (codableConceptTexts.length > 0) {
            return <List>
                {codableConceptTexts.slice(0, codableConceptTexts.length).map((codableConceptText, index) => {
                    return <List.Item key={index}><List.Icon name='angle double right' color='blue' />{t(codableConceptText)}</List.Item>
                })}
            </List>
        }
    }



    getLogo = (serviceRequest) => {
        let src = logoorange;
        switch (serviceRequest.status) {
            case "ConsentCreated":
                src = logoorange;
                break;
            case "PatientMessageSent":
                src = logoorange;
                break;
            case "SendPatientMessageFailed":
                src = logoorange;
                break;
            case "SendMessageFailed":
                src = logoorange;
                break;
            case this.config.SERVICE_REQUEST_STATUS_PLANNED:
                src = logoyellow;
                break;
            case this.config.SERVICE_REQUEST_STATUS_WAITING_LIST:
                src = logoorange;
                break;
            case this.config.SERVICE_REQUEST_STATUS_SUPPORT:
                src = logogreen;
                break;
            case this.config.SERVICE_REQUEST_STATUS_ALREADY_SUPPORTED:
                src = logogreen;
                break;
            case this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT:
                src = logored;
                break;
            case this.config.SERVICE_REQUEST_STATUS_FORWARD:
                src = logogrey;
                break;
            case this.config.SERVICE_REQUEST_STATUS_NO_SHOW:
                src = logored;
                break;
            case this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT_WANTED:
                src = logored;
                break;
            case this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT_POSSIBLE:
                src = logored;
                break;
            default:
                src = logogreen;
                break;
        }
        return <Image
            circular
            src={src}
        />
    }

    getSocialContext = (serviceRequest) => {
        const { t } = this.props;
        if (serviceRequest.notes) {
            return <Segment basic>
                <Header>
                    <Icon name='info' color='blue' />
                    <Header.Content>
                        {t("Social Context")}
                        <Header.Subheader>{t("Social Context Description")}</Header.Subheader>
                    </Header.Content>
                </Header>
                <Divider />
                <Segment basic>
                    {serviceRequest.notes}
                </Segment>
            </Segment>
        }
    }

    render() {
        const { t } = this.props;
        if (this.state.practitioner && this.state.patient && this.state.patientZipCode) {
            return <MediaContextProvider>
                <Segment attached>
                    <Container as={Media} lessThan="computer">
                        <Grid columns={1}>
                            <Grid.Column width={16}>
                                <Header>
                                    {this.getLogo(this.state.serviceRequest)}{t("Request")}</Header>
                            </Grid.Column>
                        </Grid>
                    </Container>
                    <Container as={Media} greaterThanOrEqual="computer">
                        <Grid columns={2}>
                            <Grid.Column width={8}>
                                <Header>
                                    {this.getLogo(this.state.serviceRequest)}{t("Request")}</Header>
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='right'>
                                <Header size='small'>{this.state.healthcareService.name}</Header>
                            </Grid.Column>
                        </Grid>
                    </Container>
                    <Divider />
                    <Grid columns={3} stackable>
                        <Grid.Column>
                            <Segment basic>
                                <Header>
                                    <Icon name='doctor' color='blue' />
                                    <Header.Content>
                                        {this.state.serviceRequest.encounter.participant.name}
                                        <Header.Subheader>{t("SR Requester")}g</Header.Subheader>
                                    </Header.Content>
                                </Header>
                                <Divider />
                                <Header size='small'>{t("Organisation")}</Header>
                                {this.state.practitionerRole.organization.name}
                                <Header size='small'>{t("Role")}</Header>
                                {this.state.practitionerRole.speciality}
                                <Header size='small'>{t("Contact")}</Header>
                                {this.getMail(this.state.practitioner.email)}
                                {this.getPhone(this.state.practitioner.phone)}
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment basic>
                                <Header>
                                    <Icon name='user' color='blue' />
                                    <Header.Content>
                                        {this.state.serviceRequest.encounter.subject.name}
                                        <Header.Subheader>{t("Person With Support Need")}</Header.Subheader>
                                    </Header.Content>
                                </Header>
                                <Divider />
                                <Header size='small'>{t("Age")}</Header>
                                {getAge(this.state.patient.birthdate)} {t("Years")}
                                <Header size='small'>{t("Residence")}</Header>
                                {this.state.patientZipCode}
                                <Header size='small'>{t("Contact")}</Header>
                                {this.getMail(this.state.patient.email)}
                                {this.getPhone(this.state.patient.phone)}
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment basic>
                                <Header>
                                    <Icon name='hand paper' color='blue' />
                                    <Header.Content>
                                        {t("Needs")}
                                        <Header.Subheader>
                                            {t("Needs Description")}</Header.Subheader>
                                    </Header.Content>
                                </Header>
                                <Divider />
                                <Segment basic>
                                    {this.getCodableConceptText(this.state.serviceRequest)}
                                </Segment>
                            </Segment>
                            {this.getSocialContext(this.state.serviceRequest)}
                        </Grid.Column>
                    </Grid>
                </Segment>
                {this.showRegistrationSegment()}
                {this.showRegistrationButton()}
            </MediaContextProvider>
        } else {
            return <Loader active inline='centered' size='large' />;
        }
    }
}

function WithNavigateWrapper(props) {
    const navigate = useNavigate();
    const location = useLocation();
    return <ServiceRequest {...props} navigate={navigate} location={location} />;
}

export default withTranslation()(WithNavigateWrapper);

